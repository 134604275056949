/**
 * @license FLConsult 
 * (C) - All Rights Reserved
 * 
 * This source code is protected under international copyright law.  All rights
 * reserved and protected by the copyright holders.
 * This file is confidential and only available to authorized individuals with the
 * permission of the copyright holders.  If you encounter this file and do not have
 * permission, please contact the copyright holders and delete this file.
 */

import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Employee, Payroll } from '../model/employee';
import { BehaviorSubject, ReplaySubject, Subject, catchError, finalize, throwError } from 'rxjs';
import { WebAPICommunicator } from './web-api-communicator.interface';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService extends WebAPICommunicator {
  private employeeSubject: ReplaySubject<Employee> = new ReplaySubject(1);
  employee$ = this.employeeSubject.asObservable();
  private employeeTTL: number = 0;
  isUpdatingEmployee = false;

  private payrollSubject: BehaviorSubject<Payroll[]> = new BehaviorSubject([] as Payroll[]);
  payroll$ = this.payrollSubject.asObservable();  
  get payroll(): Payroll[] {
    return this.payrollSubject.getValue();
  }
  private payrollTTL: number = 0;
  isUpdatingPayroll = false;

  constructor() {
    super();
  }

  public clearCache(): void {
    this.employeeSubject.complete();
    this.employeeSubject = new ReplaySubject(1);
    this.employee$ = this.employeeSubject.asObservable();
    this.employeeTTL = 0;
    this.isUpdatingEmployee = false; 

    this.payrollSubject.next([] as Payroll[]); 
    this.payrollTTL = 0;
    this.isUpdatingPayroll = false;
  }

  updateEmployee(force: boolean = false) {
    if(this.isUpdatingEmployee) return;
      this.isUpdatingEmployee = true; 
    if (!this.doRefresh(this.employeeTTL,force)) {
      this.isUpdatingEmployee = false; 
      return;
    }
    this.createGetRequest<Employee>("/Uniconta/Employee")
      .pipe(
        catchError((err) => {
          this.isUpdatingEmployee = false; 
          return this.handleError(err)
        })
      ).subscribe((result) => {
        if (result.ok) {
          this.employeeSubject.next(result.result);
          this.employeeTTL = Date.now();
          this.isUpdatingEmployee = false; 
        }
      })
  }

  setCloseDate(closeDate: string) {
    let token = this.session.getToken();
    this.createPostRequest<Employee>("/Uniconta/SetEmployeeTimeOpenDate", {
      companyId: token!.companyId,
      username: token!.username,
      token: token!.token,
      date: closeDate
    }).pipe(
      catchError((err) => {
        return this.handleError(err)
      })
    ).subscribe((result) => {
      if (result.ok) {
        this.employeeSubject.next(result.result);
        this.employeeTTL = Date.now();
      }
    })
  }

  updatePayroll(force: boolean = false) {
    if(this.isUpdatingPayroll) return;
      this.isUpdatingPayroll = true; 
    if (!this.doRefresh(this.payrollTTL, force)) {
      this.isUpdatingPayroll = false; 
      return;
    }
    this.createGetRequest<Payroll[]>("/Uniconta/EmployeePayroll")
      .pipe(
        catchError((error: HttpErrorResponse) => {
          this.isUpdatingPayroll = false; 
          return this.handleError(error);
        }),
      ).subscribe((result) => {
        if (result.ok) {
          this.payrollSubject.next(result.result);
          this.payrollTTL = Date.now();
          this.isUpdatingPayroll = false; 
        }
      });
  }

  handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong.
      console.error(
        `Backend returned code ${error.status}, body was: `, error.error);
    }
    // Return an observable with a user-facing error message.
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
